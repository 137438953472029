<template>
  <div>
    <v-overlay :value="$apollo.queries.zantyData.loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <v-card class="mb-4 px-4 pb-4">
      <v-row>
        <v-col
          sm="6"
          cols="12"
        >
          <DataType
            @selectedDataTypeChanged="changeSelectedDataType"
          />
        </v-col>

        <v-col
          sm="6"
          cols="12"
        >
          <ReportType
            @selectedReportTypeChanged="changeSelectedReportType"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <TimeFrameFilter
            :date-time.sync="time"
            :are-hours-disabled="selectedReportType == 'DAILY'"
          />
        </v-col>
      </v-row>
    </v-card>

    <!-- <v-card class="mb-4 px-4 pb-4 pt-3">
    </v-card> -->

    <v-card class="mb-4 px-2 py-2">
      <ZantyDataMap
        :sensors="zantyData"
        :selected-data-type="selectedDataType"
        :selected-sensor="selectedSensor"
        @goToSensorMapClick="goToSensorMapClick"
      />
    </v-card>

    <v-card
      class="mt-n16 mb-5"
      style="z-index: 2;"
      width="240"
      elevation="3"
      rounded="xl"
    >
      <v-card-title
        v-if="selectedDataType != 'particulates_1'"
        class="ml-2 pb-3 body-2"
      >
        Levels of the measured data:
      </v-card-title>

      <v-card-text>
        <TempHumidityLegend
          v-if="selectedDataType == 'air_temp_deg_c' || selectedDataType == 'humidity_pct'"
        />

        <ParticleZeroPointOneLegend
          v-else-if="selectedDataType == 'particulates_1'"
        />

        <ParticleLegend
          v-else
        />
      </v-card-text>
    </v-card>

    <v-card>
      <SensorList
        :sensors="zantyData"
        :selected-sensor-pin="selectedSensorMapClick"
        @goToSensor="goToSensor"
      />
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */

import ZantyDataMap from '../components/zanty/ZantyDataMap.vue'
import DataType from '../components/zanty/DataType.vue'
import ParticleLegend from '../components/zanty/ParticleLegend.vue'
import ParticleZeroPointOneLegend from '../components/zanty/ParticleZeroPointOneLegend.vue'
import TempHumidityLegend from '../components/zanty/TempHumidityLegend.vue'
import SensorList from '../components/zanty/SensorList.vue'
import TimeFrameSlider from '../components/zanty/TimeFrameSlider.vue'
import ReportType from '../components/heatmaps-shared/ReportType.vue'

import gql from 'graphql-tag'
import TimeFrameFilter from '../components/zanty/TimeFrameFilter.vue'

export default {
  components: {
    ZantyDataMap,
    DataType,
    ParticleZeroPointOneLegend,
    ParticleLegend,
    TempHumidityLegend,
    SensorList,
    TimeFrameSlider,
    ReportType,
    TimeFrameFilter
},

  apollo: {
    zantyData: {
      query: gql`
        query ($reportType: ReportType!, $time: DateTimeTz) {
          zantyData(reportType: $reportType, time: $time) {
            pin
            air_temp_deg_c
            humidity_pct
            carbon_monoxide
            sulphur_dioxide
            nitrogen_monoxide
            nitrogen_dioxide
            ozone
            particulates_1
            particulates_10
            particulates_25
          }
        }
      `,
      variables() {
        return {
          reportType: this.selectedReportType,
          time: this.time,
        }
      },
    },
  },

  data() {
    return {
      selectedDataType: 'air_temp_deg_c',
      selectedReportType: 'HOURLY',
      time: this.initCurrentDateTime(),
      selectedSensor: undefined,
      selectedSensorMapClick: [],
    }
  },

  methods: {
    changeSelectedDataType(selectedDataType) {
      this.selectedDataType = selectedDataType
    },

    changeSelectedReportType(selectedReportType) {
      this.selectedReportType = selectedReportType
    },

    // changeSelectedTime(time) {
    //   this.time = time
    // },

    goToSensor(pin) {
      this.selectedSensor = pin
    },

    goToSensorMapClick(pin) {
      this.selectedSensorMapClick.splice(0)

      this.selectedSensorMapClick.push(pin.lat, pin.lng)
    },

    initCurrentDateTime() {
      return this.$moment().set({ minutes: 0, seconds: 0 }).format('YYYY-MM-DDTHH:mm:ssZ')
    },
  },
}
</script>

<style scoped>
.v-application.theme--light .v-sheet.v-alert:not(.v-sheet--outlined) {
  background: red;
}
</style>
