<template>
  <div>
    <div class="pl-1 mb-1">
      Data type
    </div>
    <div>
      <v-select
        v-model="selectedDataType"
        :items="dataTypes"
        solo
        hide-details
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataTypes: [
        { text: 'Temperature', value: 'air_temp_deg_c' },
        { text: 'Humidity', value: 'humidity_pct' },
        { text: 'Carbon monoxide', value: 'carbon_monoxide' },
        { text: 'Sulphur dioxide', value: 'sulphur_dioxide' },
        { text: 'Nitrogen monoxide', value: 'nitrogen_monoxide' },
        { text: 'Nitrogen dioxide', value: 'nitrogen_dioxide' },
        { text: 'Ozone', value: 'ozone' },
        { text: 'Particulate matter 0.1', value: 'particulates_1' },
        { text: 'Particulate matter 2.5', value: 'particulates_25' },
        { text: 'Particulate matter 10', value: 'particulates_10' },
      ],

      selectedDataType: 'air_temp_deg_c',
    }
  },

  watch: {
    selectedDataType: {
      handler() {
        this.selectedDataTypeChanged()
      },
    },
  },

  methods: {
    selectedDataTypeChanged() {
      this.$emit('selectedDataTypeChanged', this.selectedDataType)
    },
  },
}
</script>
