<template>
  <div class="d-flex">
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <div class="pl-1 mb-1">
          Date
        </div>
        <v-menu
          ref="dateRef"
          v-model="menu.date"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              class="cursor-pointer"
              readonly
              solo
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="datePicker"
            v-model="date"
            no-title
            :min="options.minDate"
            :max="options.maxDate"
            @click:date="$refs.dateRef.save(date)"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <div class="pl-1 mb-1">
          Time
        </div>
        <v-menu
          ref="timeRef"
          v-model="menu.time"
          :close-on-content-click="false"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="areHoursDisabled"
        >
          <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
            <v-tooltip
              top
              :disabled="!areHoursDisabled"
            >
              <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                <v-text-field
                  v-model="time"
                  class="cursor-pointer"
                  readonly
                  solo
                  hide-details
                  v-bind="{ ...menuAttrs, ...tooltipAttrs }"
                  v-on="{ ...menuOn, ...tooltipOn }"
                ></v-text-field>
              </template>
              <span>Time of day is disabled when using <b>`Daily Report Type`</b></span>
            </v-tooltip>
          </template>
          <v-time-picker
            ref="timePicker"
            v-model="time"
            format="24hr"
            no-title
            :allowed-minutes="allowedMinuteStep"
            @click:hour="setFullHour"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars, object-curly-newline
import { getCurrentInstance, ref, watch, computed, reactive, onMounted } from '@vue/composition-api'

export default {
  props: {
    areHoursDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateTime: {
      type: String,
      default: () => '',
    },
  },

  emits: ['update:date-time'],

  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const menu = reactive({
      date: ref(false),
      time: ref(false),
    })

    // const dateRef = ref(false)
    // const timeRef = ref(false)
    // const timePicker = ref(false)

    const options = reactive({
      minDate: vm.$moment().subtract(7, 'days').toISOString(),
      maxDate: vm.$moment().toISOString(),
    })

    const getCurrentDate = () => vm.$moment().format(vm.$moment.HTML5_FMT.DATE)
    const getCurrentTime = () => vm.$moment().format('HH').concat(':', '00')

    const date = ref(null)
    const time = ref(null)

    const selectedDateTime = computed(() =>
      vm.$moment(`${date.value} ${time.value || '00:00:00'}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ssZ'))

    onMounted(() => {
      if (props.dateTime) {
        // eslint-disable-next-line no-underscore-dangle
        const _tmpDateTime = vm.$moment(props.dateTime)
        date.value = _tmpDateTime.format('YYYY-MM-DD')
        time.value = _tmpDateTime.format('HH:mm')
      } else {
        date.value = getCurrentDate()
        time.value = props.areHoursDisabled ? null : getCurrentTime()
      }
    })

    watch(() => props.areHoursDisabled, (newVal, oldVal) => {
      if (newVal && !oldVal) {
        time.value = null
      } else {
        time.value = getCurrentTime()
      }
    })

    watch(selectedDateTime, () =>
      emit('update:date-time', selectedDateTime.value))

    const allowedMinuteStep = m => m % 15 === 0

    const setFullHour = h => {
      vm.$nextTick(() => {
        vm.$refs.timePicker.selectingHour = true
      })
      vm.$refs.timeRef.save(String(h).padStart(2, 0).concat(':', '00'))
    }

    return {
      menu,

      // dateRef,
      // timeRef,
      // timePicker,

      options,

      date,
      time,

      allowedMinuteStep,
      setFullHour,
    }
  },
}
</script>
