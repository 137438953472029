var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"pl-1 mb-1"},[_vm._v(" Date ")]),_c('v-menu',{ref:"dateRef",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"readonly":"","solo":"","hide-details":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu.date),callback:function ($$v) {_vm.$set(_vm.menu, "date", $$v)},expression:"menu.date"}},[_c('v-date-picker',{ref:"datePicker",attrs:{"no-title":"","min":_vm.options.minDate,"max":_vm.options.maxDate},on:{"click:date":function($event){return _vm.$refs.dateRef.save(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"pl-1 mb-1"},[_vm._v(" Time ")]),_c('v-menu',{ref:"timeRef",attrs:{"close-on-content-click":false,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.areHoursDisabled},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
var menuAttrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.areHoursDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"readonly":"","solo":"","hide-details":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',Object.assign({}, menuAttrs, tooltipAttrs),false),Object.assign({}, menuOn, tooltipOn)))]}}],null,true)},[_c('span',[_vm._v("Time of day is disabled when using "),_c('b',[_vm._v("`Daily Report Type`")])])])]}}]),model:{value:(_vm.menu.time),callback:function ($$v) {_vm.$set(_vm.menu, "time", $$v)},expression:"menu.time"}},[_c('v-time-picker',{ref:"timePicker",attrs:{"format":"24hr","no-title":"","allowed-minutes":_vm.allowedMinuteStep},on:{"click:hour":_vm.setFullHour},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }