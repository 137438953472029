<template>
  <div>
    <div class="pl-1 mb-1">
      Report type
    </div>

    <v-select
      v-model="selectedReportType"
      :items="reportTypes"
      solo
      hide-details
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedReportType: 'HOURLY',
    }
  },

  computed: {
    reportTypes() {
      return [
        { text: 'Hourly', value: 'HOURLY' },
        { text: 'Daily', value: 'DAILY' },
      ]
    },
  },

  watch: {
    selectedReportType: {
      handler() {
        this.$emit('selectedReportTypeChanged', this.selectedReportType)
      },
    },
  },
}
</script>
