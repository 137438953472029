<template>
  <div>
    <v-slider
      v-model="day"
      ticks="always"
      tick-size="4"
      min="0"
      max="7"
      :tick-labels="dayLabels"
    ></v-slider>

    <v-slider
      v-model="hour"
      ticks="always"
      tick-size="4"
      min="0"
      :max="day == 7 ? new Date().getHours() : 23"
      :tick-labels="hourLabels"
      :disabled="areHoursDisabled"
    ></v-slider>
  </div>
</template>

<script>
export default {
  props: {
    numberOfSteps: {
      type: Number,
      required: false,
      default: null,
    },
    areHoursDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      day: 7,
      hour: 0,
      dayLabels: [],
      hourLabels: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
    }
  },

  computed: {
    selectedTime() {
      const now = this.$moment().subtract(7, 'days')
      now.add(this.day, 'days')

      if (this.areHoursDisabled) {
        now.set({ hour: 0, minutes: 0, seconds: 0 })
      } else {
        now.set({ hour: this.hour, minutes: 0, seconds: 0 })
      }

      const [nowIso] = now.toISOString().split('.')

      return `${nowIso}+00:00`
    },
  },

  watch: {
    selectedTime: {
      handler() {
        this.$emit('selectedTimeChanged', this.selectedTime)
      },
    },
  },

  created() {
    let now = this.$moment().subtract(7, 'days')
    now = now.startOf('hour')

    for (let i = 0; i <= 7; i += 1) {
      this.dayLabels[i] = now.format('dddd, DD.MM.')
      now = now.add(1, 'day')
    }
  },

  mounted() {
    this.$emit('selectedTimeChanged', this.selectedTime)
  },

  methods: {},
}
</script>
