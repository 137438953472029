<template>
  <l-map
    ref="map"
    class="map-styling"
    style="height: 800px; width: 100%"
    :zoom="zoom"
    :center="center"
    :layers="layers.layer"
    :options="leafletOptions"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
    @update:bounds="boundsUpdated"
  >
    <l-tile-layer
      :url="$tileService.url"
      :attribution="$tileService.attribution"
    ></l-tile-layer>

    <l-circle-marker
      v-for="sensor in sensors"
      :key="sensor.id"
      :lat-lng="sensor.pin"
      :radius="radius"
      :color="pinColor(sensor[selectedDataType])"
      :fill-color="pinColor(sensor[selectedDataType])"
      :fill-opacity="1"
      @click="goToSensor"
    ></l-circle-marker>
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LCircleMarker,
} from 'vue2-leaflet'

export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
  },

  props: {
    sensors: undefined,
    selectedDataType: {
      type: String,
      default: 'air_temp_deg_c',
    },
    selectedSensor: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      zoom: 11,
      center: [45.34481892275193, 14.44775250818104],
      bounds: null,
      layers: [
        {
          name: 'Weather Data',
          visible: true,
          format: 'image/png',
          layer: 'nexrad-n0r-900913',
          transparent: true,
          attribution: 'Weather data © 2012 IEM Nexrad',
        },
      ],

      leafletOptions: {
        minZoom: 11,
        maxZoom: 16,
      },

      radius: 6,

      pinColors: {
        low: '#46caff',
        normal: '#00917d',
        danger: '#ff461f',
        particulates_1: '#5173d0',
      },

      limitsForParticles: {
        air_temp_deg_c: {
          low: 10,
          normal: 28,
        },
        humidity_pct: {
          low: 30,
          normal: 50,
        },
        sulphur_dioxide: 125,
        carbon_monoxide: 10,
        nitrogen_dioxide: 80,
        nitrogen_monoxide: 10,
        ozone: 150,
        particulates_10: 50,
        particulates_25: 55,
      },

      userTriggered: false,
    }
  },

  watch: {
    selectedSensor: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.center = newVal
          this.userTriggered = true
        }
      },
      deep: true,
    },
  },

  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom
    },

    centerUpdated(center) {
      this.center = center

      if (this.userTriggered) {
        this.zoom = 14
        this.userTriggered = false
      }
    },

    boundsUpdated(bounds) {
      this.bounds = bounds
    },

    goToSensor(e) {
      this.userTriggered = true
      this.center = e.latlng
      this.$emit('goToSensorMapClick', e.latlng)
    },

    pinColor(sensorReading) {
      const readingLimits = this.limitsForParticles[this.selectedDataType]

      if (this.selectedDataType === 'particulates_1') return this.pinColors[this.selectedDataType]

      if (this.selectedDataType === 'air_temp_deg_c' || this.selectedDataType === 'humidity_pct') {
        if (sensorReading <= readingLimits.low) return this.pinColors.low

        if (
          sensorReading > readingLimits.low
          && sensorReading <= readingLimits.normal
        ) {
          return this.pinColors.normal
        }

        if (sensorReading > readingLimits.normal) return this.pinColors.danger
      } else {
        if (sensorReading <= readingLimits) return this.pinColors.normal
        if (sensorReading > readingLimits) return this.pinColors.danger
      }

      return this.pinColors.normal
    },
  },
}
</script>
