<template>
  <div class="px-2 py-2">
    <v-simple-table :class="[]">
      <template #default>
        <tbody>
          <tr>
            <th
              scope="col"
              class="text-center"
            ></th>
            <th
              scope="col"
              class="text-center"
            >
              Coordinates
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Temperature (°C)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Humidity (%)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Carbon monoxide (µg/m3)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Nitrogen monoxide (µg/m3)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Nitrogen dioxide (µg/m3)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Ozone (µg/m3)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Particulates 0.1 (µg/m3)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Particulates 2.5 (µg/m3)
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Particulates 10 (µg/m3)
            </th>
          </tr>
          <tr
            v-for="sensor in sensors"
            :key="sensor.id"
            :class="[ JSON.stringify(sensor.pin) === JSON.stringify(selectedSensorPinMutable) ? 'selected-sensor' : '', 'text-center' ]"
          >
            <td>
              <v-btn
                text
                color="primary"
                @click="goToSensor(sensor.pin)"
              >
                <v-icon>
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </td>
            <td> {{ sensor.pin }} </td>
            <td> {{ sensor.air_temp_deg_c }} </td>
            <td> {{ sensor.humidity_pct }} </td>
            <td> {{ sensor.carbon_monoxide }} </td>
            <td> {{ sensor.nitrogen_monoxide }} </td>
            <td> {{ sensor.nitrogen_dioxide }} </td>
            <td> {{ sensor.ozone }} </td>
            <td> {{ sensor.particulates_1 }} </td>
            <td> {{ sensor.particulates_25 }} </td>
            <td> {{ sensor.particulates_10 }} </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mdiEyeOutline } from '@mdi/js'

export default {
  props: {
    sensors: undefined,
    selectedSensorPin: {
      typeof: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      selectedSensorPinMutable: [],

      icons: {
        mdiEyeOutline,
      },
    }
  },

  watch: {
    selectedSensorPin: {
      handler(newVal) {
        this.selectedSensorPinMutable = newVal
      },
    },
  },

  methods: {
    goToSensor(pin) {
      this.selectedSensorPinMutable = pin
      this.$emit('goToSensor', pin)
    },
  },
}
</script>

<style>
.selected-sensor {
  background: #f2f2f2;
}

.theme--dark .selected-sensor {
  background: #414141;
}

.v-slider__track-fill {
  width: 100% !important;
}

.v-slider__tick--filled {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
